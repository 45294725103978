import axios, { AxiosError } from "axios";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Constants } from "../../../utilities/Constants";

interface ISchedulerStatus {
    readonly _id: string;
    readonly created: Date;
    readonly activeJob: string;
    readonly lastActive: string;
    readonly revision: number;
    readonly jobData?: {
        readonly _id: string;
        readonly completed: string | null;
        readonly submissionA: string;
        readonly submissionB: string;
        readonly submissions: [
            {
                readonly _id: string;
                readonly alias: string;
            },
            {
                readonly _id: string;
                readonly alias: string;
            }
        ];
    };
}

interface IActiveSchedulersResponse {
    readonly activeSchedulers: Array<ISchedulerStatus>;
}

const getSchedulers = async (token: string | null) => {
    const config = getAxiosRequestConfig(token);
    const uri = `${Constants.ApiRoot}/schedulers`;
    try {
        const { data } = await axios.get<IActiveSchedulersResponse>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return null;
        }
        throw error;
    }
};

export const useSchedulers = () => {
    const { token } = useContext(AuthContext);

    return useQuery(["schedulers"], () => getSchedulers(token));
};
