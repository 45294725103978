import axios, { AxiosError } from "axios";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Constants } from "../../../utilities/Constants";

interface IPendingQueueResponse {
    readonly pendingJobs: Array<{
        readonly _id: string;
        readonly isValidationGame: boolean;
        readonly isRatedGame: boolean;
        readonly created: string;
    }>;
}

const getQueue = async (token: string | null) => {
    const config = getAxiosRequestConfig(token);
    const uri = `${Constants.ApiRoot}/queue`;
    try {
        const { data } = await axios.get<IPendingQueueResponse>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return null;
        }
        throw error;
    }
};

export const useQueue = () => {
    const { token } = useContext(AuthContext);

    return useQuery(["queue"], () => getQueue(token));
};
