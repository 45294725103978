import { PrimaryButton } from "@fluentui/react";
import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useQueue } from "../../../../hooks/api/scheduler/useQueue";
import { useSchedulers } from "../../../../hooks/api/scheduler/useSchedulers";
import { getRelativeTime } from "../../../../utilities/getRelativeTime";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { H1 } from "../../../H1/H1";
import { Spinner } from "../../../Spinner/Spinner";
import { Label, Root } from "./JobQueue.styles";

const getJobStatus = (t: TFunction, completed: string | null, activeJobId: string | null) => {
    if (activeJobId === null) {
        return t("jobQueuePage.idling");
    }
    if (completed === null) {
        return activeJobId;
    }
    if (completed !== null) {
        return t("jobQueuePage.idlingCompleted");
    }
};

export const JobQueueContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const schedulerData = useSchedulers();
    const queueData = useQueue();
    if (schedulerData.status === "loading" || queueData.status === "loading") {
        return <Spinner />;
    }

    const schedulerList = schedulerData.data?.activeSchedulers.map((item) => {
        const { _id, lastActive, activeJob, jobData } = item;
        const currentActiveJob = getJobStatus(t, jobData?.completed ?? null, activeJob);
        const submissionDetails = jobData?.submissions.map((submission) => submission.alias).join(" v ") ?? t("jobQueuePage.noAgents");
        return (
            <li key={_id}>
                <span>
                    <Label>{t("jobQueuePage.schedulerId")}</Label> {_id}, <Label>{t("jobQueuePage.activeJob")}</Label> {currentActiveJob},{" "}
                    <Label>{t("jobQueuePage.lastStatusCheck")}</Label> {getRelativeTime(t, lastActive)},{" "}
                    <Label>{t("jobQueuePage.agents")}</Label> {submissionDetails}
                </span>
            </li>
        );
    });

    const queueList = queueData.data?.pendingJobs.map((item, index) => {
        const { _id, isValidationGame, isRatedGame, created } = item;
        const queuePosition = index + 1;
        const isRatedLabel = isRatedGame ? t("jobQueuePage.true") : t("jobQueuePage.false");
        const isValidationGameLabel = isValidationGame ? t("jobQueuePage.true") : t("jobQueuePage.false");
        return (
            <li key={_id}>
                <span>
                    <Label>{queuePosition}. </Label> {_id}, <Label>{t("jobQueuePage.isValidationGame")}</Label> {isValidationGameLabel},{" "}
                    <Label>{t("jobQueuePage.isRated")}</Label> {isRatedLabel}, <Label>{t("jobQueuePage.created")}</Label>{" "}
                    {getRelativeTime(t, created)}
                </span>
            </li>
        );
    });

    const onRefreshClicked = () => {
        schedulerData.refetch();
        queueData.refetch();
    };

    return (
        <Root>
            <ContentCard maxWidth="100%" onClick={onRefreshClicked}>
                <PrimaryButton>Refresh</PrimaryButton>
                <H1>{t("jobQueuePage.schedulerStatus")}</H1>
                <ul>{schedulerList}</ul>
                <H1>{t("jobQueuePage.currentPendingQueue")}</H1>
                <ul>{queueList}</ul>
            </ContentCard>
        </Root>
    );
};
