import styled from "styled-components";

export const Root = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
`;

export const Label = styled.span`
    font-weight: 600;
`;
